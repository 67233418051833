import { useFetch, UseFetchResponse } from '@asg/core';
import type { Division } from 'packages/user-administration/types/division';
import type { ErrorResponse, ResponseDataPagination } from 'types/general';

import { api } from '../api/endpoints';

type RequestListProps = {
  limit?: number;
  page?: number;
  endpoint: string;
};

export type ResponseList = {
  loading: boolean;
  data: Division[];
};

export const useGetDivisionList = ({ limit = 25, page = 1, endpoint }: RequestListProps): ResponseList => {
  const { loading, data } = useFetch(`${endpoint}?page=${page}&limit=${limit}`, {}, [page, limit]);
  return { loading, data };
};

export const useGetDivision = (id: string): UseFetchResponse<Division> => ({
  ...useFetch(`${api.divisionList}/${id}`, [id]),
});

export const useEditDivision = (
  id: string,
): {
  save: (division: Division) => Promise<any>;
  loading: boolean;
} => {
  const { put, loading } = useFetch(`${api.divisionList}/${id}`);

  const save = (division: Division) => put(division);

  return { save, loading };
};

export const useRemoveDivision = (
  id: string,
): {
  remove: () => Promise<any>;
  loading: boolean;
} => {
  const { del, loading } = useFetch(`/api/divisions/${id}`, [id]);

  const remove = () => del();

  return { remove, loading };
};

export const useCreateDivision = (): {
  create: (division: Division) => Promise<any | ErrorResponse>;
  loading: boolean;
} => {
  const { post, loading } = useFetch('/api/user-administration/division');

  const create = (division: Division) => post(division);

  return { create, loading };
};

export const useGetDivisionOptions = () => {
  return useFetch(
    api.divisionList,
    {
      onNewData: (_, nextData: ResponseDataPagination<Division[]>) =>
        nextData.data?.map((division) => ({
          value: division.name,
          label: division.title,
        })),
    },
    [],
  );
};
