export const api = {
  setPassword: 'api/user-administration/user/:user-id/password/new',
  profile: 'api/user-administration/me',
  agentList: 'api/user-administration/agent',
  userListGrid: 'api/user-administration/grid/user',
  userList: 'api/user-administration/user',
  roleListGrid: 'api/user-administration/grid/role',
  roleList: 'api/user-administration/role',
  permissionsList: 'api/user-administration/permission_group',
  divisionListGrid: 'api/user-administration/grid/division',
  rolePermission: 'api/user-administration/permission/group',
  divisionList: 'api/user-administration/division',
  resetUserPassword: 'api/user-administration/v1/user/:id/password/reset',

  userVacationChange: 'api/user-administration/user_vacation/:id',
  userVacationGrid: 'api/user-administration/user/:id/user_vacation',
  userVacation: 'api/user-administration/user/:id/user_vacation',
};
