import { generatePath } from 'react-router-dom';

import { Profile } from 'packages/user-administration/types/users';
import { getHashPassword } from 'services/auth/utils';
import rtk from 'shared/api/rtk';

import { api } from './endpoints';

export const userApi = rtk.injectEndpoints({
  endpoints: (build) => ({
    resetUserPassword: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: generatePath(api.resetUserPassword, { id }),
      }),
    }),
    getProfile: build.query<Profile, void>({
      query: () => ({
        url: api.profile,
      }),
      providesTags: ['profile'],
    }),
    getAgent: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.agentList}/${id}`,
      }),
    }),
    saveAgent: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.agentList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    getUser: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.userList}/${id}`,
      }),
    }),
    saveUser: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.userList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    getRole: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.roleList}/${id}`,
      }),
    }),
    saveRole: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.roleList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    getDivision: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.divisionList}/${id}`,
      }),
    }),
    saveDivision: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.divisionList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    setPassword: build.mutation<void, { userId: string | number; confirmPassword: string; password: string }>({
      query: ({ password, confirmPassword, userId }) => ({
        url: api.setPassword.replace(':user-id', userId?.toString()),
        method: 'POST',
        credentials: 'include',
        body: {
          passwordHash: getHashPassword(password),
          passwordHashConfirm: getHashPassword(confirmPassword),
        },
      }),
    }),
  }),
});

export const { useSetPasswordMutation, useGetProfileQuery } = userApi;
