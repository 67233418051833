import React, { useMemo } from 'react';

import { TableGridAsync, LayoutController, LayoutBase, LayoutHeader } from '@asg/core';
import routersUrl from 'configs/routes-static';
import routerConfig from 'configs/routes-static';
import { useListContext } from 'contexts/lists';
import { api } from 'packages/user-administration/api/endpoints';
import { isDeletedStatus } from 'utils/dictionaries/roles';

import 'packages/user-administration/components/roles/hoc/roles-grid.scss';

const optionsIdDeletedStatus = Object.entries(isDeletedStatus).map(([value, label]) => ({ value, label }));

const HOCRoles = (): JSX.Element => {
  const { permissionsGroup } = useListContext();

  const permissions = useMemo(
    () =>
      permissionsGroup?.reduce((dict, permission) => {
        dict[permission.value] = permission.label;
        return dict;
      }, {} as Record<string, string>),
    [permissionsGroup],
  );

  return (
    <LayoutBase>
      <LayoutHeader
        title="Список ролей пользователей"
        hideBackLink
      />

      <LayoutController
        buttons={[
          {
            label: 'Добавить роль',
            color: 'orange',

            href: routersUrl.cabinet.roles.create.url,
          },
          // {
          //  label: 'Выгрузить в Excel',
          //  color: 'orange',
          // },
        ]}
      />

      <TableGridAsync
        numerable
        type="standard"
        endpoint={api.roleListGrid}
        name={api.roleListGrid}
        className="role-table"
        headers={[
          {
            title: 'Наименование роли',
            value: 'name',
            filter: {
              type: 'simple',
              path: 'title',
            },
            sortable: true,
            width: 200,
            render: (_, row) => (row.isDeleted ? `${row.title as string} (удалена)` : row.title),
          },
          {
            title: 'Функциональные возможности',
            value: 'permissionGroups',
            sortable: true,
            render: (value) => value?.map((v: string) => permissions[v] ?? v).join(', ') ?? '',
            width: 400,
          },
          {
            title: 'Статус',
            value: 'isDeleted',
            filter: {
              type: 'selector',
              selectFilter: {
                options: optionsIdDeletedStatus,
              },
            },
            sortable: true,
            render: (value) => isDeletedStatus[value],
          },
        ]}
        getLink={(row) => routerConfig.cabinet.roles.role.url.replace(':id', row.name)}
      />
    </LayoutBase>
  );
};

export default HOCRoles;
