import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { api as endpoints } from 'services/get-api-host';
import { ErrorResponse } from 'types/general';

import { API_HOST } from 'app/configs';

type CustomBaseQueryFn = BaseQueryFn<string | FetchArgs, unknown, { data?: ErrorResponse; status: number }>;

const baseQueryWithAuth: CustomBaseQueryFn = fetchBaseQuery({
  baseUrl: API_HOST,
  credentials: 'include',
}) as CustomBaseQueryFn;

const mutex = new Mutex();

const baseQuery: CustomBaseQueryFn = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  const request = async () => {
    return baseQueryWithAuth(args, api, extraOptions);
  };

  const result = await request();

  if (result?.error && result?.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQueryWithAuth(
          {
            url: `${endpoints.auth.refreshToken}`,
            method: 'POST',
            credentials: 'include',
          },
          api,
          extraOptions,
        );

        if (refreshResult?.data) {
          return request();
        }
      } catch {
        window.history.pushState({}, '', `/login?redirectUrl=${window.location.pathname}`);
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      return request();
    }
  }

  return result;
};

const rtk = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ['profile'],
  endpoints: () => ({}),
});

export default rtk;
