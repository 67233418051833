import React from 'react';

import { TableGridAsync, LayoutController, LayoutBase, LayoutHeader } from '@asg/core';
import routerConfig from 'configs/routes-static';
import routersUrl from 'configs/routes-static';
import { api } from 'packages/user-administration/api/endpoints';
import { isDeletedStatus } from 'utils/dictionaries/divisions';

const optionsIaDeletedStatus = Object.entries(isDeletedStatus).map(([value, label]) => ({ label, value }));

const HOCDivisions = (): JSX.Element => (
  <LayoutBase>
    <LayoutHeader
      title="Список подразделений"
      hideBackLink
    />

    <LayoutController
      buttons={[
        {
          label: 'Добавить подразделение',
          color: 'orange',

          href: routersUrl.cabinet.divisions.create.url,
        },
        // {
        //  label: 'Выгрузить в Excel',
        //  color: 'orange',
        //
        // },
      ]}
    />

    <TableGridAsync
      numerable
      type="standard"
      endpoint={api.divisionListGrid}
      name={api.divisionListGrid}
      getLink={(data) => routerConfig.cabinet.divisions.division.url.replace(':id', data.name)}
      headers={[
        {
          title: 'Наименование подразделения',
          value: 'title',
          hasFilter: true,
          sortable: true,
        },
        {
          title: 'Вышестоящее подразделение',
          value: 'parentTitle',
          hasFilter: true,
          sortable: true,
        },
        {
          title: 'Статус',
          value: 'isDeleted',
          filter: {
            type: 'selector',
            selectFilter: {
              options: optionsIaDeletedStatus,
            },
          },
          sortable: true,
          render: (value) => isDeletedStatus[value],
        },
      ]}
    />
  </LayoutBase>
);

export default HOCDivisions;
