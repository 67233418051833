import { useHistory } from 'react-router-dom';

import { CustomIncomingOptions, useFetch, UseFetchResponse } from '@asg/core';
import generatedLinks from 'configs/routes-static';
import sha512 from 'js-sha512';
import { useSetPasswordMutation } from 'packages/user-administration/api/users';
import { PermissionGroup } from 'packages/user-administration/types/roles';
import { CreateAbsenceUserType, User, UserVacationChangeType } from 'packages/user-administration/types/users';
import { useProfile } from 'slices';
import type { ErrorResponse } from 'types/general';

import { api } from '../api/endpoints';

export const useGetUser = (id: string): UseFetchResponse<User> => useFetch(`${api.userList}/${id}`, [id]);

export type UserFetchForm = Omit<User, 'role' | 'division'> & {
  roleName?: string;
  divisionName?: string;
};

export const useEditUser = (
  id: string,
): {
  data: UseFetchResponse<User>;
  save: (user: UserFetchForm) => Promise<any | ErrorResponse>;
  loading: boolean;
} => {
  const { put, loading, data } = useFetch(`${api.userList}/${id}`);

  const save = (user: UserFetchForm) => {
    const fetchData = {
      ...user,
      role: undefined,
    };
    return put(fetchData);
  };

  return { save, loading, data };
};

export const useCreateUser = (): {
  create: (user: UserFetchForm) => Promise<any | ErrorResponse>;
  loading: boolean;
} => {
  const { post, loading } = useFetch(`${api.userList}`);

  const create = (user: UserFetchForm) => post(user);

  return { create, loading };
};

export const useResetUserPassword = (
  id: string | undefined = '',
): {
  post: () => Promise<any | ErrorResponse>;
  loading: boolean;
} => {
  const { post, loading } = useFetch(api.resetUserPassword.replace(':id', id));

  return { post, loading };
};

export const useGetUrbManagers = (): {
  get: () => Promise<any>;
  list?: User[];
  loading: boolean;
  error?: ErrorResponse;
} => {
  const link = `${api.userList}?roleId=ROLE_URB_MANAGER`;

  const { get, data, error, loading } = useFetch(link, []);
  return { get, loading, error, list: data?.data };
};

export const useGetUnderwriters = (): {
  getUnderwriters: () => Promise<any>;
  underwriters?: any;
  loading: boolean;
  error?: ErrorResponse;
} => {
  const link = `${api.userList}?roleId=ROLE_UNDERWRITER`;

  const { get: getUnderwriters, data, error, loading } = useFetch(link, []);
  return { getUnderwriters, loading, error, underwriters: data };
};

export const useGetPermissionOptions = () => {
  return useFetch(
    api.permissionsList,
    {
      onNewData: (_, nextData: { permissionGroups: PermissionGroup[] }) =>
        nextData.permissionGroups?.map((group) => ({
          value: group.name,
          label: group.title,
          options: group.permissions?.map((permission) => ({
            label: permission.title,
            value: permission.name,
          })),
        })),
    },
    [],
  );
};

type UseSetPasswordResponse = {
  setPassword: (password: string, confirmationPassword: string) => Promise<any | ErrorResponse>;
  loading: boolean;
};

function hexToBase64(hexstring: string) {
  return window.btoa(
    hexstring
      .match(/\w{2}/g)
      ?.map((a) => String.fromCharCode(parseInt(a, 16)))
      .join('') || '',
  );
}

function getHashPassword(password: string) {
  const hash = sha512.sha512;
  const hexPassword = hash.create().update(password).hex();
  return hexToBase64(hexPassword);
}

export const useGetUserVacation = (id: number | string) => {
  return useFetch(api.userVacationGrid.replace(':id', String(id)));
};

export const useGetSpecialist = (): {
  getSpecialistInfo: () => Promise<any>;
  experts?: any;
  loading: boolean;
  error?: ErrorResponse;
} => {
  const link = `${api.userList}?roleId=ROLE_UKS_LOSS_SPECIALIST`;

  const { get: getSpecialistInfo, error, loading } = useFetch(link);
  return { getSpecialistInfo, loading, error };
};

export const useGetUsers = (queryParams = '', options?: CustomIncomingOptions) => {
  const link = `${api.userList}${queryParams && `?${queryParams}`}`;
  return useFetch(link, options);
};

export const useGetUsersOptions = (
  queryParams = '',
  { valueKey = 'id' as keyof User, labelKey = 'fullName' as keyof User } = {},
) => {
  const link = `${api.userList}${queryParams && `?${queryParams}`}`;
  return useFetch(
    link,
    {
      onNewData: (_, newData) => {
        return newData.data?.map((user: User) => ({
          value: user[valueKey],
          label: user[labelKey],
        }));
      },
    },
    [],
  );
};

export const useUserVacation = (id?: number | string) => {
  const { put, delete: deleteUserVacation } = useFetch(api.userVacationChange.replace(':id', String(id)));
  const { post } = useFetch(api.userVacation.replace(':id', String(id)));

  const createUserVacation = (body: CreateAbsenceUserType) => post(body);
  const changeUserVacation = (body: UserVacationChangeType) => put(body);

  return {
    changeUserVacation,
    deleteUserVacation,
    createUserVacation,
  };
};

export const useSetPassword = () => {
  const profile = useProfile();
  const [reset, { isLoading: loading }] = useSetPasswordMutation();

  const history = useHistory();

  const setPasswordPost = (password: string, confirmationPassword: string) => {
    if (password === confirmationPassword) {
      return reset({
        password: password,
        confirmPassword: password,
        userId: profile.userId,
      }).then((response) => {
        if (!response.error) {
          history.push(generatedLinks.auth.login.url);
        }
        return response;
      });
    }
    return Promise.resolve();
  };

  return {
    loading,
    setPassword: setPasswordPost,
  };
};
